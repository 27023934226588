/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import cn from 'classnames';
import Button from 'styleguide/components/Button/Button';
import { Field, FormikProps } from 'formik';
import Input from 'styleguide/components/Formik/Input/Input';
import { ReactNode } from 'react';

interface FieldProps {
  title: string;
  name: string;
}

interface Props<FormikValuesType> {
  first?: boolean;
  fieldDisplayTitle: string;
  fieldDisplayValue: string;
  fields: FieldProps[];
  formikProps: FormikProps<FormikValuesType>;
  fieldOverride?: ReactNode;
}

interface State {
  editMode: boolean;
}

class SettingsFieldRow<FormikValuesType> extends React.Component<Props<FormikValuesType>, State> {
  constructor(props) {
    super(props);

    this.state = { editMode: false };

    this.editButtonClick = this.editButtonClick.bind(this);
    this.cancelButtonClick = this.cancelButtonClick.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<Props<FormikValuesType>>, prevState: Readonly<State>): void {
    if (prevState !== this.state) {
      if (this.state.editMode === false) {
        this.props.fields.forEach(field => {
          this.props.formikProps.values[field.name] = this.props.formikProps.initialValues[field.name];
          delete this.props.formikProps.touched[field.name];
          delete this.props.formikProps.errors[field.name];
        });
      }
    }
  }

  editButtonClick() {
    this.setState({ editMode: true });
  }

  cancelButtonClick() {
    this.setState({ editMode: false });
  }

  fieldType(name: string) {
    let type;
    switch (name) {
      case 'password':
      case 'confirmation':
        type = 'password';
        break;
      case 'email':
        type = 'email';
        break;
      default:
        type = 'text';
    }
    return type;
  }

  render() {
    return (
      <>
        <div
          className={cn(
            this.state.editMode
              ? 'hidden'
              : 'mt-4 flex flex-row justify-between rounded border border-gray-300 px-4 py-5',
          )}
        >
          <div className="flex flex-col justify-center">
            <div className="font-hvMedium">{this.props.fieldDisplayTitle}</div>
            <div className="">{this.props.fieldDisplayValue}</div>
          </div>
          <div className="flex items-center">
            <Button
              className="!border-gray-300"
              type="button"
              color="dark"
              outline
              onClick={this.editButtonClick}
            >
              Edit
            </Button>
          </div>
        </div>
        <div className={cn(this.state.editMode ? 'mt-4 border border-gray-300 pt-4' : 'hidden')}>
          {this.props.fieldOverride ? (
            <div>{this.props.fieldOverride}</div>
          ) : (
            <div className="mt-0.5 flex grow flex-row space-x-2 px-4">
              {this.props.fields.map(field => (
                <div className="grow">
                  <Field
                    inPlaceError
                    type={this.fieldType(field.name)}
                    name={field.name}
                    component={Input}
                    label={field.title}
                  />
                </div>
              ))}
              <div className="mx-4 flex">
                <Button
                  className="self-start !border-gray-300"
                  expanded
                  type="button"
                  color="dark"
                  outline
                  onClick={this.cancelButtonClick}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>

        {/*    <Grid.Row className={this.state.editMode ? '' : css.hidden}> */}
        {/*      {!!this.props.fieldOverride ? ( */}
        {/*        <Grid.Col sm={12} md={10} className={css.field}> */}
        {/*          {this.props.fieldOverride} */}
        {/*        </Grid.Col> */}
        {/*      ) : ( */}
        {/*        this.props.fields.map(field => ( */}
        {/*          <Grid.Col className={css.field} key={field.name} sm={12} md={10 / this.props.fields.length}> */}
        {/*            <Field */}
        {/*              inPlaceError */}
        {/*              type={this.fieldType(field.name)} */}
        {/*              name={field.name} */}
        {/*              component={Input} */}
        {/*              label={field.title} */}
        {/*            /> */}
        {/*          </Grid.Col> */}
        {/*        )) */}
        {/*      )} */}
        {/*      <Grid.Col sm={12} md={2} className={css.editButtonContainer}> */}
        {/*        <Button */}
        {/*            className="mt-[-22px]" */}
        {/*          expanded={true} */}
        {/*          type={'button'} */}
        {/*          color="dark" */}
        {/*          outline */}
        {/*          onClick={this.cancelButtonClick} */}
        {/*        > */}
        {/*          Cancel */}
        {/*        </Button> */}
        {/*      </Grid.Col> */}
        {/*    </Grid.Row> */}
        {/*  </Grid.Col> */}
        {/* </Grid.Row> */}
      </>
    );
  }
}

export default SettingsFieldRow;
