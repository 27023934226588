import * as React from 'react';
import TwoColumnFormField from 'styleguide/components/forms/Field/TwoColumnFormField';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import { Field, useFormikContext } from 'formik';
import Input from 'styleguide/components/Formik/Input/Input';
import cn from 'classnames';
import { Label } from 'styleguide/components/forms';
import RadioTabs from 'styleguide/components/Formik/RadioTabs';
import RadioTab from 'styleguide/components/Formik/RadioTabs/RadioTab';
import { KeyVal } from 'libs/utils/common-types';

interface Props {
  prefix: string;
}

const appendName = (prefix, name) => {
  if (prefix === '') {
    return name;
  }
  return `${prefix}.${name}`;
};

const NotificationsForm = ({ prefix }: Props) => {
  const { values } = useFormikContext<KeyVal>();

  return (
    <TwoColumnFormField className="!mt-2 flex" heading="SMS notifications">
      <FieldWrapper
        className="mt-[-18px]"
        Label={<Label placement="left">Send project updates to your mobile device</Label>}
        Input={
          // TODO: refactor to use boolean value instead of string
          <RadioTabs>
            <Field
              name={appendName(prefix, 'sendSmsNotifications')}
              value="1"
              label="Yes"
              type="radio"
              component={RadioTab}
              checked={values.sendSmsNotifications === '1'}
            />
            <Field
              name={appendName(prefix, 'sendSmsNotifications')}
              value="0"
              label="No"
              type="radio"
              component={RadioTab}
              checked={values.sendSmsNotifications === '0'}
            />
          </RadioTabs>
        }
      />
      <Field inPlaceError name={appendName(prefix, 'smsPhone')} component={Input} label="Mobile Number" />

      <p className={cn('text-sm leading-5 -md:mb-12 -md:mt-4', 'm-0 p-0')}>
        Enable updates about the status of your project to a mobile device.
      </p>
    </TwoColumnFormField>
  );
};

export default NotificationsForm;
